import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, CircularProgress, Button } from "@material-ui/core";
import { Delete, Add, Edit } from "@material-ui/icons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { fetchTicket, removeTicket } from "../../redux/action/ticket.action";
import Moment from "moment";

const Ticket = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ticketList, reload } = useSelector((state) => state.ticketReducer);
  useEffect(() => {
    dispatch(fetchTicket());
  }, [dispatch, reload]);
  console.log(ticketList)
  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Backdrop style={{ zIndex: "10000", color: "#fff" }} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MaterialTable
        title="Invoice"
        columns={[
          { title: "Reg. No", field: "regNo" },
          { title: "Invoice. No", field: "regNo" },
          { title: "Name", field: "customerName" },
          {
            title: "Date",
            field: "date",
            render: (rowData) => (
              <p>{Moment(rowData?.date).format("DD-MM-YYYY")}</p>
            ),
          },
          {title:"Payment" , field:"payment"},
          { title: "Make of Car", field: "makeOfCar" },
          { title: "Model" , field: "carModel" },
          {
            title: "Details",
            field: "details",
            render: (rowData) => (
              <Button
                style={{
                  textTransform: "none",
                }}
                color="primary"
                variant="contained"
                onClick={() => {
                  history.push({
                    pathname: "/home/ticket/detail",
                    data: rowData,
                  });
                }}
              >
                Details
              </Button>
            ),
          },
        ]}
        data={ticketList}
        components={{
          Action: (props) => {
            switch (props?.action?.icon) {
              case "add":
                return (
                  <Button
                    onClick={(event) => props.action.onClick(event, props.data)}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                    }}
                  >
                    <Add /> Add Invoice
                  </Button>
                );
              case "edit":
                return (
                  <Edit
                    onClick={(event) => props.action.onClick(event, props.data)}
                    style={{
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  />
                );
              case "delete":
                return (
                  <Delete
                    onClick={(event) => props.action.onClick(event, props.data)}
                    style={{
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  />
                );
              default:
                return (
                  <Button
                    onClick={(event) => props.action.onClick(event, props.data)}
                    color="primary"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    size="small"
                  >
                    default
                  </Button>
                );
            }
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add Food",
            isFreeAction: true,
            onClick: (event, rowData) => {
              history.push({
                pathname: "/home/ticket/add",
              });
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Food",
            onClick: (event, rowData) => {
              history.push({
                pathname: "/home/ticket/edit",
                data: rowData,
              });
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Food",
            onClick: (event, rowData) => {
              dispatch(removeTicket(rowData?._id));
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData:true,
          headerStyle:{fontWeight: "bold",
           color: "white" ,
          background: "#3f51b5"}
        }}
      />
    </div>
  );
};

export default Ticket;
