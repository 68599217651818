import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBSEamkgj5RoYllmMKal_3pLUvrflB0jGg",
    authDomain: "motowheel-bdb66.firebaseapp.com",
    projectId: "motowheel-bdb66",
    storageBucket: "motowheel-bdb66.appspot.com",
    messagingSenderId: "1017392017459",
    appId: "1:1017392017459:web:c6931ce529e501943bb346"
};
// initialize app
  firebase.initializeApp(firebaseConfig);

  export const auth = firebase.auth();
  export const db = firebase.firestore()
  export const storage = firebase.storage()
  export const ticketRef = db.collection("Ticket")